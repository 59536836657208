import logoIMG from '@/assets/logo.png';
import Group from '@/components/Group';
import { userInfo } from '@/request';
import useBreakpoint from '@/utils/useBreakpoint';
import { useSwrQuery } from '@/utils/useQuery';
import { Close, LogView, User, Wab321019 } from '@react95/icons';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppBar, Avatar, Button, MenuList, MenuListItem, Separator, Toolbar } from 'react95';
import styled from 'styled-components';

const OutletWrapper = styled.div`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  min-height: calc(100vh - 48px);

  .window-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .window {
    width: 100%;
  }
`;

const FullscreenOutletWrapper = styled(OutletWrapper)`
  width: 100%;
  max-width: 100vw;

  .window {
    flex: 1;
  }
`;

const NormalOutletWrapper = styled(OutletWrapper)`
  max-width: 80vw;
  min-width: 300px;
`;

export default function MainLayout() {
  const navigate = useNavigate();
  const fullscreenBreakpoint = useBreakpoint('sm');
  const xsBreakpoint = useBreakpoint('xs');
  const [open, setOpen] = useState(false);
  const [click, setClick] = useState(0);

  const { data } = useSwrQuery(userInfo, {
    allowUnAuth: true,
  });

  const onLogout = useCallback(() => {
    window.location.href = '/logout';
  }, []);

  useEffect(() => {
    if (click >= 5) {
      navigate('/nyan');
    }
  }, [click]);

  const onClick = useCallback(() => {
    console.log('Click', click + 1);
    setClick((x) => x + 1);
    setTimeout(() => {
      setClick((x) => x - 1);
    }, 1000);
  }, [setClick]);

  const OWrapper = fullscreenBreakpoint ? FullscreenOutletWrapper : NormalOutletWrapper;

  return (
    <>
      <AppBar
        style={{
          zIndex: 100,
          flex: 1,
        }}
      >
        <Toolbar style={{ justifyContent: 'space-between' }} onClick={onClick}>
          {xsBreakpoint ? (
            <>
              <Button
                onClick={() => setOpen((x) => !x)}
                active={open}
                style={{ fontWeight: 'bold' }}
              >
                Menu
              </Button>
              {open && (
                <MenuList
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '100%',
                    fontWeight: 'bold',
                  }}
                  onClick={() => setOpen(false)}
                >
                  <MenuListItem onClick={() => navigate('/')}>
                    <img
                      src={logoIMG}
                      alt="react logo"
                      style={{ height: '20px', marginRight: 4 }}
                    />
                    Start
                  </MenuListItem>
                  <MenuListItem onClick={() => navigate('/user')}>
                    <User
                      variant="16x16_4"
                      style={{ height: '16px', width: '16px', marginRight: 4 }}
                    />
                    User
                  </MenuListItem>
                  <MenuListItem onClick={() => navigate('/team')}>
                    <Wab321019
                      variant="32x32_4"
                      style={{ height: '16px', width: '16px', marginRight: 4 }}
                    />
                    Team
                  </MenuListItem>
                  <MenuListItem onClick={() => navigate('/log')}>
                    <LogView
                      variant="32x32_4"
                      style={{ height: '16px', width: '16px', marginRight: 4 }}
                    />
                    Log
                  </MenuListItem>
                  {data && (
                    <>
                      <Separator />
                      <MenuListItem>
                        <Avatar src={data?.userAvatar} alt="avatar" noBorder size={16}>
                          {data?.nickname ?? data?.username}
                        </Avatar>
                        {data?.nickname ?? data?.username}
                      </MenuListItem>
                      <MenuListItem onClick={onLogout}>
                        <Close
                          variant="16x16_4"
                          style={{ height: '16px', width: '16px', marginRight: 4 }}
                        />
                        Logout
                      </MenuListItem>
                    </>
                  )}
                </MenuList>
              )}
            </>
          ) : (
            <>
              <Group gap={4}>
                <Button onClick={() => navigate('/')} style={{ fontWeight: 'bold' }}>
                  <img src={logoIMG} alt="react logo" style={{ height: '20px', marginRight: 4 }} />
                  Start
                </Button>
                <Button onClick={() => navigate('/user')} style={{ fontWeight: 'bold' }}>
                  <User
                    variant="16x16_4"
                    style={{ height: '16px', width: '16px', marginRight: 4 }}
                  />
                  User
                </Button>
                <Button onClick={() => navigate('/team')} style={{ fontWeight: 'bold' }}>
                  <Wab321019
                    variant="32x32_4"
                    style={{ height: '16px', width: '16px', marginRight: 4 }}
                  />
                  Team
                </Button>
                <Button onClick={() => navigate('/log')} style={{ fontWeight: 'bold' }}>
                  <LogView
                    variant="32x32_4"
                    style={{ height: '16px', width: '16px', marginRight: 4 }}
                  />
                  Log
                </Button>
              </Group>
              {data && (
                <Group
                  gap={4}
                  align="center"
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {data?.nickname ?? data?.username}
                  <Avatar src={data?.userAvatar} alt="avatar">
                    {data?.nickname ?? data?.username}
                  </Avatar>
                  <Button onClick={onLogout} style={{ fontWeight: 'bold' }}>
                    <Close
                      variant="16x16_4"
                      style={{ height: '16px', width: '16px', marginRight: 4 }}
                    />
                    Logout
                  </Button>
                </Group>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      <OWrapper>
        <Outlet />
      </OWrapper>
    </>
  );
}
