import client from "@/utils/client";
import type { ResponseConfig } from "@/utils/client";
import type { UserInfoQueryResponse } from "../models/UserInfo";

 /**
 * @summary 获取用户信息
 * @link /api/v1/user/info
 */
export async function userInfo(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UserInfoQueryResponse>["data"]> {
    const res = await client<UserInfoQueryResponse>({ method: "get", url: `/api/v1/user/info`, ...options });
    return res.data;
}