import { useEffect } from 'react';

import useSwr from 'swr';

import { ApiError, RequestConfig } from '@/utils/client';

export interface Response<T> {
  code?: number;
  data?: T;
  error?: string;
  nums?: number;
}

export type ApiFunction<Params extends unknown[], T> = (
  ...params: [...Params, Partial<RequestConfig<unknown>> | undefined]
) => Promise<Response<T>>;

export async function callQueryRaw<Params extends unknown[], T>(
  func: ApiFunction<Params, T>,
  config: Partial<RequestConfig<unknown>> = {},
  ...params: Params
): Promise<Response<T>> {
  try {
    const res = await func(...params, config);
    if (res.code !== 200) {
      throw new ApiError(res.error ?? 'error', res.code ?? 500, res);
    }
    if (!res.data) {
      throw new ApiError('Invalid response', 500, res);
    }
    return res;
  } catch (error) {
    let data = (error as ApiError)?.data;
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }

    throw error;
  }
}

export async function callQuery<Params extends unknown[], T>(
  func: ApiFunction<Params, T>,
  config: Partial<RequestConfig<unknown>> = {},
  ...params: Params
): Promise<T> {
  const res = await callQueryRaw(func, config, ...params);
  return res.data!;
}

export function useSwrQuery<Params extends unknown[], T>(
  func: ApiFunction<Params, T>,
  config: Partial<RequestConfig<unknown>> = {},
  ...params: Params
) {
  const res = useSwr<T, ApiError>(`${func.name}-${JSON.stringify(params)}`, () =>
    callQuery(func, config, ...params),
  );

  useEffect(() => {
    if (res.error && !config.allowUnAuth) {
      if (res.error.status === 401) {
        window.location.href = '/oauth2/authorization/logto';
      }
    }
  }, [res.error, config]);

  return res;
}

export function useSwrQueryRaw<Params extends unknown[], T>(
  func: ApiFunction<Params, T>,
  config: Partial<RequestConfig<unknown>> = {},
  ...params: Params
) {
  const res = useSwr<Response<T>, ApiError>(`${func.name}-${JSON.stringify(params)}`, () =>
    callQueryRaw(func, config, ...params),
  );

  useEffect(() => {
    if (res.error && !config.allowUnAuth) {
      if (res.error.status === 401) {
        window.location.href = '/oauth2/authorization/logto';
      }
    }
  }, [res.error, config]);

  return res;
}
