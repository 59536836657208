import { styleReset } from 'react95';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';

import '@react95/icons/icons.css';

/* Pick a theme of your choice */
import original from 'react95/dist/themes/original';

/* Original Windows95 font (optional) */
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import { Theme } from 'react95/dist/types';
import { router } from './routers';

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
  }

  body {
    background: ${({ theme }) => (theme as Theme).desktopBackground};
  }
`;

const WrapperDiv = styled.div`
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const App = () => (
  <ThemeProvider theme={original}>
    <GlobalStyles />
    <WrapperDiv>
      <Suspense fallback={<div>Loading</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </WrapperDiv>
  </ThemeProvider>
);

export default App;
