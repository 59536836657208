import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

export interface GroupProps {
  direction?: 'row' | 'column';
  gap?: number;
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  style?: React.CSSProperties;
}

export default function Group(props: PropsWithChildren<GroupProps>) {
  return (
    <Wrapper
      style={{
        flexDirection: props.direction,
        gap: `${props.gap ?? 16}px`,
        justifyContent: props.justify,
        alignItems: props.align,
        ...props.style,
      }}
    >
      {props.children}
    </Wrapper>
  );
}
