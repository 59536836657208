// Breakpoint	Viewport width	Value in px
// xs	36em	576px
// sm	48em	768px
// md	62em	992px
// lg	75em	1200px
// xl	88em	1408px

import { useEffect, useState } from 'react';

const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1408,
};

export default function useBreakpoint(point: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const updateValue = () => {
      setValue(window.innerWidth < breakpoints[point]);
    };

    updateValue();

    window.addEventListener('resize', updateValue);
    return () => window.removeEventListener('resize', updateValue);
  }, [point]);

  return value;
}
