import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';

const ErrorPage = lazy(() => import('@/pages/ErrorPage'));
const HomePage = lazy(() => import('@/pages/HomePage'));
const UserPage = lazy(() => import('@/pages/user/UserPage'));
const TeamPage = lazy(() => import('@/pages/team/TeamPage'));
const LogPage = lazy(() => import('@/pages/LogPage'));
const NyanPage = lazy(() => import('@/pages/nyan/NyanPage'));
const AllTeamsPage = lazy(() => import('@/pages/team/AllTeamsPage'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/user',
        element: <UserPage />,
      },
      {
        path: '/team',
        element: <TeamPage />,
      },
      {
        path: '/all-teams',
        element: <AllTeamsPage />,
      },
      {
        path: '/log',
        element: <LogPage />,
      },
      {
        path: '/nyan',
        element: <NyanPage />,
      },
    ],
  },
]);
